/**
 * 佛事登记信息配置表
 * @table fs
 * fs flow字段数据结构
 *  {
 *      type: number, // 1 认领 2审核通过 3审核不通过 4派单 5开牌 6确认开牌 7驳回开牌 8重新开牌 9重新派单
 *      user_id: string, // 操作人企业微信userId
 *      time: string, // 操作时间节点 格式 yyyy-MM-dd HH:mm:ss
 *  }
 */

import axios from "../js/req";
import {clone, getDate, getKeyTitle, getRadioOptionsFromSelect} from "../js/tool";
import {buddhaTypeList, getBuddhaSubTypeName, getBuddhaTypeName, getBuddhaNeedOpen, getBuddhismShowName} from "./type";
import {isBuddhaAdmin} from "../constant/org";
import {getLunarDay} from "../../components/calendar/lunar";
import {getTemple, getUserWxId} from "../js/storage";

// 佛事看板颜色
export const buddhismColors = ["#FF4272", "#FF8E33", "#4FCB7F", "#36CBCB", "#6699FF", "#5157F7"];

// 时辰枚举
export const timeList = [
    {key: 'unknown', title: '吉时（时辰未知）'},
    {key: '23:00-00:59', title: '23:00-00:59 子时'},
    {key: '01:00-02:59', title: '01:00-02:59 丑时'},
    {key: '03:00-04:59', title: '03:00-04:59 寅时'},
    {key: '05:00-06:59', title: '05:00-06:59 卯时'},
    {key: '07:00-08:59', title: '07:00-08:59 辰时'},
    {key: '09:00-10:59', title: '09:00-10:59 巳时'},
    {key: '11:00-12:59', title: '11:00-12:59 午时'},
    {key: '13:00-14:59', title: '13:00-14:59 未时'},
    {key: '15:00-16:59', title: '15:00-16:59 申时'},
    {key: '17:00-18:59', title: '17:00-18:59 酉时'},
    {key: '19:00-20:59', title: '19:00-20:59 戌时'},
    {key: '21:00-22:59', title: '21:00-22:59 亥时'},
]

// 拜忏、诵经开牌法师类型列表
const normalMasterTypeList = [
    {name: '磬', ability: '磬'},
    {name: '大鱼', ability: '大鱼'},
    {name: '铛', ability: '铛'},
    {name: '铪', ability: '铪'},
    {name: '小鼓', ability: '小鼓'},
    {name: '小鱼', ability: '小鱼'},
    {name: '香灯', ability: '香灯'},
    {name: '司鼓', ability: '司鼓'},
    {name: '监坛', ability: '监坛'},
    {name: '师', master: true, min: 1, max: 5},
]

export const dateTypes = [
    {key: 0, title: '不显示日期'},
    {key: 1, title: '显示日期'},
    {key: 2, title: '选择日期'},
]
export const buddhismStates = [
    {key: 1, title: '待认领'},
    {key: 2, title: '待审核'},
    {key: 3, title: '审核不通过'},
    {key: 4, title: '审核通过'},
    {key: 5, title: '已派单'},
    {key: 9, title: '待开牌'},
    {key: 10, title: '已开牌'},
    {key: 11, title: '已确认'},
    {key: 12, title: '开牌被驳回'},
    {key: 6, title: '已完成'},
    {key: 7, title: '已取消'},
    {key: 8, title: '已退款'},
]

export const buddhismOrderStatus = [
    {key: 0, title: '未支付'},
    {key: 1, title: '待支付'},
    {key: 2, title: '已支付'},
    {key: 3, title: '已退款'},
    {key: 4, title: '已取消'},
    {key: 5, title: '已完成'},
    {key: 6, title: '退款中'},
    {key: 7, title: '已支付'},
    {key: 8, title: '未支付'},
]

/*旧的佛事/法会类型配置 暂时弃用*/
export const buddhismTypeList = [
    {key: 1, title: '普佛'},
    {
        key: 2,
        title: '拜忏',
        // 开牌法师类型列表
        tabletMasterTypeList: clone(normalMasterTypeList)
    },
    {
        key: 3,
        title: '诵经',
        // 开牌法师类型列表
        tabletMasterTypeList: clone(normalMasterTypeList)
    },
    {
        key: 4,
        title: '焰口',
        noTablet: true,
        tabletTypeList: [6, 7],
        person: {num: 2, max: 4}, // 牌位输入配置 num 数量 max 字数
        posterity: {num: 2, max: 4}, // 阳上输入配置 num 数量 max 字数
        tabletMasterTypeList: {
            type_6: [
                {name: '主法'},
                {name: '师', multiple: true},
                {name: '铛', ability: '铛'},
                {name: '小木鱼', ability: '小木鱼'},
                {name: '引磬', ability: '磬'},
                {name: '手鼓', ability: '手鼓'},
                {name: '铪', ability: '铪'},
                {name: '香灯', ability: '香灯'},
                {name: '鼓', ability: '鼓'},
                {name: '监坛', ability: '监坛'},
            ],
            type_7: [
                {name: '主法'},
                {name: '二座'},
                {name: '三座'},
                {name: '师', multiple: true},
                {name: '铛', ability: '铛'},
                {name: '小木鱼', ability: '小木鱼'},
                {name: '引磬', ability: '磬'},
                {name: '手鼓', ability: '手鼓'},
                {name: '铪', ability: '铪'},
                {name: '香灯', ability: '香灯'},
                {name: '鼓', ability: '鼓'},
                {name: '监坛', ability: '监坛'},
            ]
        }
    },
    {key: 5, title: '祈福', noTablet: true, noRelation: true},
    {key: 6, title: '梁皇法会', tabletTypeList: [1, 2]},
    {key: 7, title: '华严法会', tabletTypeList: [1, 2]},
    {key: 8, title: '地藏法会', tabletTypeList: [1, 2]},
    {key: 9, title: '水陆法会', tabletTypeList: [3, 4, 5]},
]

export const tabletList = [
    {key: 1, title: '长生禄位'},
    {key: 2, title: '往生莲位'},
]

export const tabletTypeList = [
    {key: 1, title: '小牌位'},
    {key: 2, title: '大牌位'},
    {key: 3, title: '内坛牌位'},
    {key: 4, title: '大坛大牌位'},
    {key: 5, title: '大坛小牌位'},
    {key: 6, title: '一大士'},
    {key: 7, title: '三大士'},
]

export const buddhismObjectTypeList  = [
    {key: 1, title: '个人'},
    {key: 2, title: '团体/公司'},
]

export const buddhismRelationList = [
    {key: 1, title: '氏门中历代宗亲', prev: '后羿', person: {num: 1, max: 2, place: '请输入姓氏 如：诸葛'}, posterity: {num: 3, max: 6}},
    {key: 2, title: '普利十方', prev: '施主', person: {num: 0}, posterity: {num: 3, max: 6}},
    {key: 3, title: '冤亲债主', prev: '施主', person: {num: 0}, posterity: {num: 3, max: 6}},
    {key: 4, title: '堕胎婴灵', prev: '施主', person: {num: 0}, posterity: {num: 3, max: 6}},
    {key: 5, title: '先曾祖父、先曾祖母', posterityList: ['曾孙', '曾孙媳', '曾孙女', '曾孙婿']},
    {key: 6, title: '先曾外祖父、先曾外祖母', posterityList: ['曾外孙', '曾外孙媳', '曾外孙女', '曾外孙婿']},
    {key: 7, title: '先祖父、先祖母', posterityList: ['孙子', '孙儿媳', '孙女', '孙女婿']},
    {key: 8, title: '先外祖父、先外祖母', posterityList: ['外孙子', '外孙媳', '外孙女', '外孙婿']},
    {key: 9, title: '先父、先母', posterityList: ['子', '媳', '女', '婿']},
    {key: 10, title: '先姑父、先姑母', posterityList: ['侄子', '侄媳', '侄女', '侄婿']},
    {key: 11, title: '先伯父、先伯母', posterityList: ['侄子', '侄媳', '侄女', '侄婿']},
    {key: 12, title: '先叔父、先叔母', posterityList: ['侄子', '侄媳', '侄女', '侄婿']},
    {key: 13, title: '先舅父、先舅母', posterityList: ['外甥子', '外甥媳', '外甥女', '外甥婿']},
    {key: 14, title: '先姨父、先姨母', posterityList: ['外甥子', '外甥媳', '外甥女', '外甥婿']},
    {key: 15, title: '亡夫', prev: '斋主', posterityList: ['妻']},
    {key: 16, title: '亡妻', prev: '斋主', posterityList: ['夫']},
    {key: 17, title: '故兄、故嫂', posterityList: ['弟', '弟媳', '妹', '妹夫']},
    {key: 18, title: '故弟、故弟媳', posterityList: ['兄', '嫂', '姐', '姐夫']},
    {key: 19, title: '故姐、故姐夫', posterityList: ['弟', '弟媳', '妹', '妹夫']},
    {key: 20, title: '故妹、故妹夫', posterityList: ['兄', '嫂', '姐', '姐夫']},
    {key: 21, title: '故友', posterityList: ['友']},
    {key: 22, title: '故爱子、故爱女', posterityList: ['父', '母']},
    {key: 0, title: '自定义牌位', person: {num: 2, max: 6, place: '如：故友 张三'}, posterity: {num: 3, max: 6}},
]

export const buddhismPalaceList = [
    {key: "大雄宝殿", title: '大雄宝殿'},
    {key: "药师殿", title: '药师殿'},
    {key: "直指堂", title: '直指堂'},
    {key: "华严殿", title: '华严殿'},
    {key: "梵音殿", title: '梵音殿'},
    {key: "月恒堂", title: '月恒堂'},
]

const validator = (rule, value, callback) => {
    if (/[\u4E00-\u9FA5]+/.test(value)) {
        let url = `/admin/fs-black-list?filter[name]=${value}`;
        axios(url).then(res => {
            if(res.data.length > 0) {
                callback(new Error('名字不合法'));
            } else {
                callback()
            }
        }).catch(callback)
    } else {
        callback(new Error('只能输入中文字符'));
    }
}

// 往生牌位表单
export const passTabletItems = [
    {
        key: 'relation',
        label: '关系',
        component: 'a-select',
        props: {
            options: [],
            placeholder: "请选择"
        },
        style: {
            width: '251px'
        },
        rules: [{ required: true, message: '请选择', trigger: 'change' }]
    },
    {
        key: 'name',
        label: '姓名',
        component: 'a-input',
        props: {
            placeholder: "请输入姓名"
        },
        style: {
            width: '251px'
        },
        rules: [
            { required: true, message: '请输入', trigger: 'blur' },
            { validator, trigger: 'blur' },
        ]
    },
    {
        key: 'birth',
        label: '生日',
        slot: 'date',
        rules: [{ required: true, message: '请选择生时间', trigger: 'change' }]
    },
    {
        key: 'birthTime',
        label: '时辰',
        component: 'a-select',
        props: {
            options: [...timeList],
            placeholder: "时辰"
        },
        style: {
            width: '251px'
        },
        rules: [{ required: false, message: '请选择生时辰', trigger: 'change' }]
    },
    {
        key: 'die',
        label: '卒日',
        slot: 'date',
        rules: [{ required: true, message: '请选择卒时间', trigger: 'change' }]
    },
    {
        key: 'dieTime',
        label: '时辰',
        component: 'a-select',
        props: {
            options: [...timeList],
            placeholder: "时辰"
        },
        style: {
            width: '251px'
        },
        rules: [{ required: false, message: '请选择卒时辰', trigger: 'change' }]
    },
]


// 长生牌位表单
export const liveTabletItems = [
    {
        key: 'name',
        label: '姓名',
        component: 'a-input',
        props: {
            placeholder: "请输入姓名"
        },
        style: {
            width: '120px'
        },
        rules: [
            { required: true, message: '请输入', trigger: 'blur' },
            { validator, trigger: 'blur' },
        ]
    },
    {
        key: 'birth',
        label: '生日',
        slot: 'date',
        rules: [{ required: true, message: '请选择时间', trigger: 'change' }]
    },
    {
        key: 'time',
        label: '时辰',
        component: 'a-select',
        props: {
            options: [...timeList],
            placeholder: "请选择时辰"
        },
        style: {
            width: '150px'
        },
        rules: [{ required: false, message: '请选择出生时辰', trigger: 'change' }]
    },
]

// 阳上信息表单
export const posterityTabletItems = [
    {
        key: 'relation',
        label: '关系',
        component: 'a-select',
        props: {
            options: [],
            placeholder: "请选择"
        },
        style: {
            width: '135px'
        },
        rules: [{ required: true, message: '请选择', trigger: 'change' }]
    },
    {
        key: 'name',
        label: '姓名',
        component: 'a-input',
        props: {
            placeholder: "请输入姓名"
        },
        style: {
            width: '135px'
        },
        rules: [
            { required: true, message: '请输入', trigger: 'blur' },
            { validator, trigger: 'blur' },
        ]
    },
]

// 派单表单
export const orderItems = [
    {
        key: 'palace',
        label: '殿堂',
        component: 'a-select',
        props: {
            options: [],
            placeholder: "请选择殿堂"
        },
        rules: [{ required: true, message: '请选择殿堂', trigger: 'change' }]
    },
    {
        key: 'master',
        label: '选择法师',
        component: 'select-master',
        props: {
            types: [{name: '', number: -1}]
        },
        rules: [
            { required: true, message: '选择法师', trigger: 'change' },
            {
            validator: (rule, value, callback) => {
                let tip = null;
                if(value && value[0]) {
                    let t = value[0];
                    if(!t.select || t.select.length == 0) {
                        tip = '请选择法师';
                    }
                } else {
                    tip = '请选择法师';
                }
                if(tip) {
                    callback(new Error(tip));
                } else {
                    callback()
                }
            },
            trigger: 'change'
        }]
    },
    {
        key: 'notice_type',
        label: '通知时间',
        component: 'a-radio-group',
        props: {
            options: [
                { label: '立即通知', value: 1 },
                { label: '定时通知', value: 2 },
            ]
        },
        listeners: {
            change(e) {
                let val = e.target.value;
                let i = this.controls.findIndex(c => c.key == 'notice_time');
                if(val == 2 && i == -1) {
                    this.controls.splice(3, 0,
                        {
                            key: 'notice_time',
                            label: '定时通知时间',
                            component: 'a-date-picker',
                            props: {
                                placeholder: '请选择定时通知时间',
                                showTime: true,
                                format: 'YYYY-MM-DD HH:mm',
                                valueFormat: 'YYYY-MM-DD HH:mm',
                            },
                            rules: [{ required: true, message: '请选择定时通知时间', trigger: 'change' }]
                        }
                    )
                } else if(i >= 0) {
                    this.controls.splice(i ,1);
                }
            }
        },
        rules: [{ required: true, message: '请选择通知时间', trigger: 'change' }]
    },
    {
        key: 'note',
        label: '备注',
        component: 'a-textarea',
        props: {
            placeholder: "备注",
            rows: 4
        },
    },
]

//开牌表单
export const openTabletItems = [
    {
        key: 'palace',
        label: '殿堂',
        component: 'a-select',
        props: {
            options: [],
            placeholder: "请选择殿堂"
        },
        style: {
            width: '240px'
        },
        rules: [{ required: true, message: '请选择殿堂', trigger: 'change' }]
    },
    {
        key: 'master',
        label: '开牌法师',
        component: 'select-master',
        props: {
            types: [],
            showAbility: true
        },
        rules: [{
            validator: (rule, value, callback) => {
                let tip = null;
                if(value) {
                    for(let i = 0, l = value.length; i < l; i++) {
                        let t = value[i];
                        if(!t.select || t.select.length == 0) {
                            tip = `请选择${t.name}的开牌法师`;
                            break;
                        } else if(t.select && t.select.length != (t.number || 1)) {
                            tip = `需选择${t.number || 1}个${t.name}开牌法师`;
                            break;
                        }
                    }
                } else {
                    tip = '请选择开牌法师';
                }
                if(tip) {
                    callback(new Error(tip));
                } else {
                    callback()
                }
            },
            trigger: 'change'
        }]
    },
]

export const getTimeText = function (time) {
    return getKeyTitle(timeList, time);
}
export const getDateTypeName = function (type) {
    return getKeyTitle(dateTypes, type);
}
export const getBuddhismStateText = function (state) {
    return getKeyTitle(buddhismStates, state);
}
export const getBuddhismTypeText = function (type) {
    return getKeyTitle(buddhismTypeList, type);
}
export const getTabletName = function (tablet) {
    return getKeyTitle(tabletList, tablet);
}
export const getTabletTypeText = function (type) {
    return getKeyTitle(tabletTypeList, type);
}
export const getBuddhismObjectTypeText = function (type) {
    return getKeyTitle(buddhismObjectTypeList, type);
}
export const getBuddhismRelationText = function (relation) {
    //兼容小程序提交的牌位信息关系直接传名称的情况
    if(isNaN(relation * 1)) {
        return relation;
    } else {
        return getKeyTitle(buddhismRelationList, relation);
    }
}
export const getBuddhismPalaceName = function (palace) {
    return getKeyTitle(buddhismPalaceList, palace);
}

export const getBuddhismOrderStatusText = function (status) {
    return getKeyTitle(buddhismOrderStatus, status);
}

export const getBuddhismStatusText = function (buddhism) {
    let text = getBuddhismStateText(buddhism.status);
    // 兼容佛事配置为立即支付时未支付的情况
    // if(buddhism.order) {
    //     if(buddhism.order.status == 8) {
    //         text = '待支付';
    //     }
    // }
    return text;
}
export const getBuddhismWayText = function (buddhism) {
    return  buddhism.admin_id ? (isNaN(buddhism.admin_id * 1) ? "PC后台" : "小程序线下") : '小程序线上';
}

// 当前账号是否可认领佛事
export const canClaimBuddhism = function (buddhism, admin) {
    //线下登记 或者小程序提交的日常佛事审核中及已支付审核中的订单 或 法会已支付的订单 客堂超管可认领
    const cat = buddhism.tempCat;
    return admin && (buddhism.admin_id ||
        (cat == 1 && buddhism.order && [0, 7].indexOf(buddhism.order.status) >= 0) ||
        (cat == 3 && buddhism.order && buddhism.order.status == 2)
    )
}

export const getBuddhismOperateList = function (buddhism) {
    let res  = [];
    const admin = isBuddhaAdmin();
    const wxId = getUserWxId();
    buddhism.tempCat = buddhism.temp ? buddhism.temp.cat : null;
    if(buddhism.status >= 4 && admin && buddhism.tablet_info) {
        // 佛事需要打印登记信息
        if(buddhism.tablet_info && buddhism.tempCat == 1) {
            res.push({type: 'printInfo', title: '打印登记信息'});
        }
        // 有牌位信息显示打印牌位按钮
        res.push({type: 'printTablet', title: '打印牌位'});
    }
    switch (buddhism.status) {
        case 1:
            if(canClaimBuddhism(buddhism, admin)) {
                res.push({type: 'claim', title: '认领'});
            }
            break;
        case 2:
            admin && res.push({type: 'audit', title: '审核'});
            // 审核时候可预览牌位
            res.push({type: 'previewTablet', title: '预览牌位'});
            break;
        case 4:
            // 佛事线下登记 或者小程序登记已经支付可派单
            if(buddhism.temp.cat == 1 && (buddhism.admin_id || (buddhism.order && buddhism.order.status == 2)) && admin) {
                res.push({type: 'dispatch', title: '派单 '});
            }
            break;
        case 8:
            if (buddhism.user_id == wxId && buddhism.kp_user_id) {
                res.push({type: 'notifyRefund', title: '通知开牌员'})
            }
            break;
        case 9:
            // 礼忏诵经、焰口需要开牌 且只有指定开牌员能开牌
            if(buddhism.kp_user_id == wxId) {
                res.push({type: 'open', title: '开牌'});
            }
            break;
        case 12:
            // 驳回需要重新开牌 且只有指定开牌员能开牌
            if(buddhism.kp_user_id == wxId) {
                res.push({type: 'reOpen', title: '重新开牌'});
            }
            break;
        case 10:
            // 礼忏诵经、焰口需要打印开牌单
            res.push({type: 'printOrder', title: '打印开牌单'});
            if(admin) {
                res.push({type: 'confirmOrder', title: '确认'}, {type: 'rejectOrder', title: '驳回'});
            }
            break;
    }
    //已开牌、已驳回可重新派单
    if([10,12].indexOf(buddhism.status) >= 0) {
        res.push({type: 'reDispatch', title: '重新派单'})
    }
    // 本人提交的历史数据可以随时开牌
    if(buddhism.needOpen && buddhism.is_history == 1 && buddhism.user_id == wxId) {
        res.push({type: 'open', title: '开牌'});
    }
    if (admin) {
        // 日常佛事/法会审核通过、派单中、待开牌、已开牌、已确认、已驳回、佛事完成可修改登记信息
        if([4,5,6,9,10,11,12].indexOf(buddhism.status) >= 0) {
            res.push({type: 'edit', title: '修改登记信息'})
        }
        // 非完成和取消状态 可以取消登记
        if (![6, 7].includes(buddhism.status)) {
            res.push({type: 'cancel', title: '取消佛事'})
        }
        // 客堂管理员可上传支付凭证 和 删除
        res.push({type: 'uploadBill', title: '上传支付凭证'}, {type: 'del', title: '删除', theme: "danger"})
    }
    return res;
}

export const getBuddhismMaterTypeList = function (buddhism) {
    let type = buddhism.tabletType;
    let res = null;
    switch (type) {
        case "05":
        case "06":
            res = clone(normalMasterTypeList);
            break;
        case "07":
            res = [
                {name: '主法'},
                {name: '鱼', ability: '鱼'},
                {name: '铛', ability: '铛'},
                {name: '引磬', ability: '磬'},
                {name: '铪', ability: '铪'},
                {name: '小鼓', ability: '小鼓'},
                {name: '香灯', ability: '香灯'},
                {name: '司鼓', ability: '司鼓'},
                {name: '监坛', ability: '监坛'},
                {name: '师', master: true, min: 5, max: 9},
            ];
            break;
        case "08":
            res = [
                {name: '主法'},
                {name: '二座'},
                {name: '三座'},
                {name: '鱼', ability: '鱼'},
                {name: '铛', ability: '铛'},
                {name: '引磬', ability: '磬'},
                {name: '铪', ability: '铪'},
                {name: '小鼓', ability: '小鼓'},
                {name: '香灯', ability: '香灯', number: 2, min: 2, max: 2},
                {name: '司鼓', ability: '司鼓'},
                {name: '监坛', ability: '监坛'},
                {name: '师', master: true, min: 5, max: 9},
            ];
            break;
    }
    return res;
}

/**
 * 根据小类获取开牌单法师数量配置
 * @param type 佛事小类
 * @return {max: number, min: number}
 */
export const getBuddhaSubTypeMasterNumberConfig = function(type) {
    let min = 0, max = 0;
    const list = getBuddhismMaterTypeList({tabletType: type});
    if(list) {
        list.forEach(item => {
            min += item.min || 1;
            max += item.max || 1;
        })
    }
    return {min, max};
}

export const getBuddhismTypeOptions = function (list, type = 'select') {
    if(type == 'radio') {
        return getRadioOptionsFromSelect(list)
    } else {
        return list.map(b => {
            return {
                key: b.key,
                title: b.title
            }
        })
    }
}

export const hasTablet = function (type) {
    let item = buddhismTypeList.find(b => b.key == type);
    return item && !item.noTablet;
}

export const hasRelation = function ({cat, type}) {
    let flag = false;
    // 往生牌位、焰口佛事需要选择关系
    if((cat == 1 && ['02', '04', '06', '07', '08'].indexOf(type) >= 0) || (cat == 3 && ['03', '04', '08', '09', '10'].indexOf(type) >= 0)) {
        flag = true;
    }
    return flag;
}

export const hasTabletType = function (type) {
    let item = buddhismTypeList.find(b => b.key == type);
    return item && item.tabletTypeList;
}

export const getTabletConfig = function (entity, hr) {
    let {type, relation} = entity;
    // 不需要选择关系的配置
    let res = {person: {num: 0}, posterity: {num: 0}}
    let personItems = [], posterityItems = [];
    let t = buddhaTypeList.find(b => b.key == type);
    // 要选择关系
    if(hr) {
        // 找到特殊关系的特殊配置
        let r = buddhismRelationList.find(br => br.key == relation || br.title == relation);
        if(r) {
            if(r.person) {
                res.person = r.person;
            } else {
                res.personList = r.title.split("、");
                res.person.num = res.personList.length > 2 ? 2 : res.personList.length;
            }
            if(r.posterity) {
                res.posterity = r.posterity;
            } else {
                res.posterityList = r.posterityList || [];
                //亡夫、亡妻牌位和阳上只能填写一个
                if(r.key == 15 || r.key == 16) {
                    res.posterity.num = res.posterityList.length > 3 ? 3 : res.posterityList.length;
                } else {
                    //以“先/故”开头的，最多6个阳上
                    res.posterity.num = 6;
                }
            }
        }
        personItems = clone(passTabletItems);
        if(res.personList) {
            personItems[0].props.options = res.personList.map(p => {
                return {
                    key: p,
                    title: p
                }
            });
        }
        personItems[1].rules.push({max: res.person.max, message: `最多只能输入${res.person.max}个字符`, trigger: 'blur'});
        if(res.person.place) {
            personItems[1].props.placeholder = res.person.place;
        }
        // 氏门中历代宗亲 只需要输入姓氏
        if(relation == 1) {
            personItems = [personItems[1]];
        } else if(relation == 0) {
            //自定义牌位不需要选择关系
            personItems.splice(0, 1);
        }
    } else {
        res = {person: {num: 5, max: 4}, posterity: {num: 0}}
        if(t) {
            t.person && (res.person = t.person);
            t.posterity && (res.posterity = t.posterity);
        }
        personItems = clone(liveTabletItems);
        personItems[0].rules.push({max: res.person.max, message: `最多只能输入${res.person.max}个字符`, trigger: 'blur'});
    }
    posterityItems = clone(posterityTabletItems);
    posterityItems[1].rules.push({max: res.posterity.max, message: `最多只能输入${res.posterity.max}个字符`, trigger: 'blur'});
    if(res.posterity.place) {
        posterityItems[1].props.placeholder = res.posterity.place;
    }
    if(res.posterityList) {
        posterityItems[0].props.options = res.posterityList.map(p => {
            return {
                key: p,
                title: p
            }
        });
    } else {
        posterityItems.splice(0, 1);
    }
    // 法会牌位需要填写关系和姓名
    if (t?.category === 3) {
        personItems = personItems.filter(p => ['relation', 'name'].includes(p.key));
    }
    res.personItems = personItems;
    res.posterityItems = posterityItems;
    return res;
}

export const getTypeTabletList = function (type) {
    let res = null;
    let item = buddhismTypeList.find(b => b.key == type);
    if(item && item.tabletTypeList) {
        res = item.tabletTypeList.map(t => {
            let temp = tabletTypeList.find(tt => tt.key == t);
            return {
                label: temp.title,
                value: temp.key
            }
        })
    }
    return res;
}

export const setBuddhismTime = function (buddha) {
    // 计算佛事开始结束时间
    let begin = null, end = null;
    if(buddha.timeRange) {
        const range = buddha.timeRange.range;
        range.forEach(item => {
            const timeList = item.split("-");
            const ts = getDate(`${buddha.date} ${timeList[0]}`).getTime();
            const te = getDate(`${buddha.date} ${timeList[1]}`).getTime();
            if(!begin || begin > ts) {
                begin = ts;
            }
            if(!end || end < te) {
                end = te;
            }
        });
    }
    // 时间段实体不存在 视为全天
    if(begin) {
        begin = new Date(begin).pattern("yyyy-MM-dd HH:mm:ss");
    } else {
        begin = `${buddha.date} 00:00:00`;
    }
    if(end) {
        end = new Date(end).pattern("yyyy-MM-dd HH:mm:ss");
    } else {
        end = `${buddha.date} 23:59:59`;
    }
    buddha.beginTime = begin;
    buddha.endTime = end;
}

export const dealBuddhism = function (buddha) {
    if(buddha) {
        if(buddha.temp) {
            buddha.needOpen = getBuddhaNeedOpen(buddha.temp);
            buddha.fsType = buddha.temp.fs_type;
            buddha.tempName = buddha.temp.name || getBuddhaTypeName(buddha.temp.fs_type);
        } else {
            buddha.tempName = "";
        }
        buddha.statusText = getBuddhismStatusText(buddha);
        buddha.wayText = getBuddhismWayText(buddha);
        if (buddha.detail) {
            try {
                let detail = JSON.parse(buddha.detail);
                for(let key in detail) {
                    buddha[key] = detail[key];
                }
            } catch (e) {
                console.error(e)
            }
        }
        if(buddha.tablet && buddha.tablet.length > 0) {
            buddha.tablet.forEach(item => {
                try {
                    let info = JSON.parse(item.info);
                    for(let key in info) {
                        item[key] = info[key];
                    }
                } catch (e) {
                    console.error(e);
                }
                item.temp = buddha.temp;
                !item.area && (item.area = [...(buddha.area || [])]);
                !item.address && (item.address = buddha.address);
                !item.username && (item.username = buddha.username);
                !item.contact_person && (item.contact_person = buddha.contact_person);
                !item.tel && (item.tel = buddha.tel);
                !item.commit_note && (item.commit_note = buddha.commit_note);
            });
            if (buddha.temp && buddha.temp.cat == 1) {
                let item = buddha.tablet[0];
                buddha.typeName = getBuddhaSubTypeName(buddha.temp.fs_type, item.type);
                buddha.tabletType = item.type;
                if (item.user_type) {
                    buddha.user_type = item.user_type * 1;
                    buddha.userTypeText = getBuddhismObjectTypeText(item.user_type);
                }
            }
        }
        if(buddha.date){
            let date = new Date(buddha.date);
            buddha.showDate = `${date.pattern("yyyy年M月d日")} 农历${getLunarDay(date, true)}`;
            if(buddha.timeRange) {
                buddha.showTime = buddha.timeRange.name
                try {
                    const rangeInfo = JSON.parse(buddha.timeRange.time_slot);
                    buddha.timeRange.range = rangeInfo.range;
                    buddha.showTime += `(${rangeInfo.range.join(",")})`
                } catch (e) {
                    console.error(e)
                }
            }
        }
        setBuddhismTime(buddha);
        buddha.priceNum = (buddha.price / 100).toFixed(2);
        if (buddha.order) {
            buddha.order.statusText = getBuddhismOrderStatusText(buddha.order.status);
        }
        if(buddha.bill_img) {
            try {
                buddha.bill = JSON.parse(buddha.bill_img);
            } catch (e) {
                console.error(e)
            }
        } else {
            buddha.bill = null;
        }
        let flowList = [
            {time: buddha.create_time, user_id: buddha.admin_id, title: '提交登记'},
        ]
        if (buddha.flow) {
            try {
                let flow = JSON.parse(buddha.flow);
                buddha.flow = flow;
                flowList.push(...flow);
            } catch (e) {
                console.error(e);
            }
        }
        flowList.reverse();
        buddha.flowList = flowList;
    }
}

export const getFhList = function () {
    let url = `/admin/fs-temp?pageSize=100&filter[cat]=3`;
    return axios(url).then(res => {
        let list = res.data;
        return list.filter(item => {
            let flag = false;
            if(item.other_config) {
                try {
                    let conf = JSON.parse(item.other_config);
                    item.begin = conf.begin;
                    item.end = conf.end;
                    flag = true;
                } catch (e) {
                    console.error(e)
                }
            }
            return flag;
        });
    })
}

export const getMonthBuddhismList = function (month) {
    month = new Date(month);
    month.setDate(1);
    let start = month.pattern("yyyy-MM-dd HH:mm:ss");
    month.setMonth(month.getMonth() + 1);
    let end = month.pattern("yyyy-MM-dd HH:mm:ss");
    let url = `/admin/fs?expand=temp,order,palaceInfo&pageSize=1000&cat=1&temple_id=${getTemple()}&status=1,2,4,5,6,9,10`;
    url += `&filter[date][gte]=${start}&filter[date][lt]=${end}`;
    return axios(url, {noTempleFilter: true}).then(res => {
        let list = res.data;
        list.forEach(item => {
            if(item.begin_time && item.end_time) {
                item.timeText = `${getDate(item.begin_time).pattern("HH:mm")}-${getDate(item.end_time).pattern("HH:mm")}`
            } else {
                item.timeText = "未定"
            }
            // 默认画圈
            item.icon = 'circle';
            if (item.order) {
                const status = item.order.status * 1;
                // 已支付打√
                if ([2, 7].includes(status)) {
                    item.icon = 'check';
                }
                // 退款、取消打×
                else if([4, 6].includes(status)) {
                    item.icon = 'close';
                }
            }
        })
        return list;
    }).catch(() => {
        return Promise.resolve([]);
    });
}

export const getMonthBuddhismMap = function (month, pujaList, buddhaList, schedule) {
    let dateMap = {};
    month = new Date(month);
    month.setDate(1);
    let start = month.getTime();
    month.setMonth(month.getMonth() + 1);
    let end = month.getTime();
    const oneDay = 24 * 60 * 60 * 1000;
    do {
        let date = new Date(start);
        let time = date.getTime();
        let str = date.pattern("yyyy-MM-dd");
        pujaList.forEach((item, i) => {
            let begin = getDate(item.begin).getTime();
            let end = getDate(item.end).getTime();
            if(time >= begin && time <= end) {
                let b = {
                    name: item.name,
                    color: buddhismColors[i % (buddhismColors.length - 1)],
                    cat: 3
                }
                if(dateMap[str]) {
                    dateMap[str].push(b);
                } else {
                    dateMap[str] = [b]
                }
            }
        });
        const btnTypeIndex = {
            claim: 1,
            audit: 2,
            dispatch: 3,
            reDispatch: 4,
            open: 5,
            reOpen: 6,
            cancel: 7
        }
        buddhaList.forEach(item => {
            let start = getDate(item.date);
            if(start >= time && start < time + oneDay) {
                let b = {
                    name: getBuddhismShowName(item),
                    status: item.status,
                    statusText: getBuddhismOrderStatusText(item.order?.status),
                    id: item.id,
                    timeText: item.timeText,
                    palace: item.palaceInfo ? item.palaceInfo.name : null,
                    contact_person: item.contact_person,
                    tel: item.tel,
                    user_id: item.user_id,
                    cat: 1,
                    cancel: item.cancel,
                    color: hasRelation({ cat: 1, type: item.type }) ? "#FACC14" : "#FF5E42"
                }
                const btnList = getBuddhismOperateList(item);
                const i = btnList.findIndex(b => b.type === 'cancel');
                if (i >= 0) {
                    btnList.splice(i, 1);
                    b.cancel = true;
                }
                btnList.sort((a, b) => (btnTypeIndex[a.type] || 100) - (btnTypeIndex[b.type] || 100));
                b.button = btnList[0]?.title || '查看详情'
                let arr = dateMap[str];
                if(arr) {
                    arr.push(b);
                } else {
                    dateMap[str] = [b]
                }
            }
        });
        start += oneDay;
    } while (start < end);
    // 寺院日历
    if(schedule && schedule.length > 0) {
        schedule.forEach(sch => {
            if(sch.status == 0) {
                const start = new Date(sch.start_time * 1000).pattern("yyyy-MM-dd");
                const arr = dateMap[start];
                const item = { name: sch.summary  };
                if(arr) {
                    arr.push(item);
                } else {
                    dateMap[start] = [item]
                }
            }
        });
    }
    return dateMap;
}
